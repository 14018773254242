<template>
<div class="projeto"><!-- .campanha-preview -->
    <Icon />
    <Nav :landingPageOptions="landingPageOptions" />
    <router-view :landingPageOptions="landingPageOptions"/>
</div>
</template>

<script type="plain/text">
import Nav from '@/components/Nav.vue'
import Icon from '@/components/Icon.vue'
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
export default {
    nome: 'projeto',
    components: {
        Nav,
        Icon
    },
    data() {
        return {
            landingPageOptions: new CampaignLandingPageOptions()
        }
    }
}
</script>
